#home{
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-between;

    background: rgba(102, 205, 171, 0.61);

    min-height: 100vh;
}

#homeHeader {
    text-align: center;
    background: rgba(119, 136, 153, 0.6);
}

#loginForm{
    border: 2px solid lightslategray;
    padding: 2.5em;
    border-radius: 1em;
    background: rgba(119, 136, 153, 0.6);
    box-shadow: rgb(195, 204, 216) 0 0 25px 12px;

    min-width: 300px;
    width: 32em;

    min-height: 30em;

    margin: 0 auto;

    > ul{
        text-align: center;
        margin-bottom: 1em;
    }

    > *{
        border-radius: .5em;
    }

}

.ant-form-item-label{
    width: 40%;
}
