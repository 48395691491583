@import './dashboard/index.scss';

.dashboard-header{
    color: rgb(167, 177, 187);
    background-color: #36454F;
    @include flex(row, nowrap, space-between);
    font-size: 1.25em;
    padding: 0 1em;

    h1{
        color: rgba(167, 177, 187, 0.9);
        font-size: 1.3em;

    }

    svg{
        margin-top: 0.1em;
    }
}

.dashboard-content{
    padding: .75em 1.2em;
}

main{
    // desktop only
    max-height: 86.5vh;
    overflow: auto;
}

@media only screen and (min-width: 768px) {
    .dashboard-header{
        font-size: 1.25em;

        h1{
            font-size: 1.75em;
        }

        svg{
            margin-top: 0.1em;
        }
    }

    .dashboard-content{
        padding: 1em 2em;
    }
}
