footer{
    color: rgba(255, 255, 255, 0.7);
    padding: .62em 0;
    border-top: 1px solid black;
    // background: rgba(0, 0, 0, 0.65);
    background: #36454F;
    text-align: center;

    > a,
    > a:hover {
        font-weight: bold;
        color: rgba(255, 255, 255, 0.8);
    }
}
